import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/tabs/home',
     // redirectTo : 'adminpannel',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'adminpannel',
    loadChildren: () => import('./pages/enterprise/adminpannel/adminpannel.module').then( m => m.AdminpannelPageModule)
  },
  {
    path: 'categorylist',
    loadChildren: () => import('./pages/enterprise/categorylist/categorylist.module').then( m => m.CategorylistPageModule)
  },
  {
    path: 'addcategory',
    loadChildren: () => import('./pages/enterprise/addcategory/addcategory.module').then( m => m.AddcategoryPageModule)
  },
  {
    path: 'productlist',
    loadChildren: () => import('./pages/enterprise/productlist/productlist.module').then( m => m.ProductlistPageModule)
  },
  {
    path: 'addproduct',
    loadChildren: () => import('./pages/enterprise/addproduct/addproduct.module').then( m => m.AddproductPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'productdetails',
    loadChildren: () => import('./pages/productdetails/productdetails.module').then( m => m.ProductdetailsPageModule)
  },
  {
    path: 'productdetails/:id',
    loadChildren: () => import('./pages/productdetails/productdetails.module').then( m => m.ProductdetailsPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./pages/address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'paymentoption',
    loadChildren: () => import('./pages/paymentoption/paymentoption.module').then( m => m.PaymentoptionPageModule)
  },
  {
    path: 'orderconfirmation',
    loadChildren: () => import('./pages/orderconfirmation/orderconfirmation.module').then( m => m.OrderconfirmationPageModule)
  },   {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'customerlist',
    loadChildren: () => import('./pages/enterprise/customerlist/customerlist.module').then( m => m.CustomerlistPageModule)
  },
  {
    path: 'orderlist',
    loadChildren: () => import('./pages/enterprise/orderlist/orderlist.module').then( m => m.OrderlistPageModule)
  },
  {
    path: 'grocery-list',
    loadChildren: () => import('./pages/grocery-list/grocery-list.module').then( m => m.GroceryListPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'addstore',
    loadChildren: () => import('./pages/enterprise/addstore/addstore.module').then( m => m.AddstorePageModule)
  },
  {
    path: 'storelist',
    loadChildren: () => import('./pages/enterprise/storelist/storelist.module').then( m => m.StorelistPageModule)
  },
  {
    path: 'inventorylist',
    loadChildren: () => import('./pages/enterprise/inventorylist/inventorylist.module').then( m => m.InventorylistPageModule)
  },
  {
    path: 'addstock',
    loadChildren: () => import('./pages/enterprise/addstock/addstock.module').then( m => m.AddstockPageModule)
  },
  {
    path: 'managestockoption',
    loadChildren: () => import('./pages/enterprise/managestockoption/managestockoption.module').then( m => m.ManagestockoptionPageModule)
  },
  {
    path: 'bulkupload',
    loadChildren: () => import('./pages/enterprise/bulkupload/bulkupload.module').then( m => m.BulkuploadPageModule)
  },
  {
    path: 'bill',
    loadChildren: () => import('./pages/bill/bill.module').then( m => m.BillPageModule)
  },
  {
    path: 'bill/:id',
    loadChildren: () => import('./pages/bill/bill.module').then( m => m.BillPageModule)
  },  {
    path: 'bizcategorylist',
    loadChildren: () => import('./pages/enterprise/bizcategorylist/bizcategorylist.module').then( m => m.BizcategorylistPageModule)
  },
  {
    path: 'addbizcategory',
    loadChildren: () => import('./pages/enterprise/addbizcategory/addbizcategory.module').then( m => m.AddbizcategoryPageModule)
  },

 






];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
